<template>
  <el-dialog
    :title="schoolStudentData.schoolName"
    :visible.sync="schoolStudentData.isOpen"
    width="1200px"
    :before-close="closeHandler"
    center
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <el-radio-group v-model="dateSelect" @change="showMonthHandler">
          <el-radio-button label="按专业显示"></el-radio-button>
          <el-radio-button label="按月显示"></el-radio-button>
          <el-radio-button label="按日显示"></el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="16">
        <el-row :gutter="20" v-if="dateSelect === '按专业显示'">
          <el-col :span="8">
            <el-date-picker
              v-model="queryInfo.startTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择开始日期"
              @change="listMajorStudentVo"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="8">
            <el-date-picker
              v-model="queryInfo.endTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择结束日期"
              @change="listMajorStudentVo"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="8">
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
        <el-date-picker
          v-if="!showMonth && dateSelect !== '按专业显示'"
          v-model="year"
          type="year"
          value-format="yyyy"
          placeholder="请选择年"
          @change="listYearStudentVo"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="12">
        <el-date-picker
          v-if="showMonth && dateSelect !== '按专业显示'"
          v-model="month"
          type="month"
          placeholder="请选择月份"
          @change="listMonthStudentVo"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24" v-if="dateSelect === '按专业显示'">
        <div id="echarts-box8" style="width: 1200px;height: 600px;"></div>
      </el-col>
      <el-col :span="24" v-if="!showMonth && dateSelect !== '按专业显示'">
        <div id="echarts-box6" style="width: 1200px;height: 600px;"></div>
      </el-col>
      <el-col :span="24" v-if="showMonth && dateSelect !== '按专业显示'">
        <div id="echarts-box7" style="width: 1200px;height: 600px;"></div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import {
  listMajorStudentVo,
  listMonthSchoolStudentVo,
  listYearSchoolStudentVo
} from '@/api/homeChart'
import * as echarts from 'echarts'

export default {
  props: {
    schoolStudentData: {
      isOpen: Boolean,
      schoolId: String,
      schoolName: String
    }
  },
  data () {
    return {
      dateSelect: '按专业显示',
      showMonth: false,
      year: new Date(),
      month: new Date(),
      monthList: [],
      monthStudentList: [],
      dayList: [],
      dayStudentList: [],
      queryInfo: {
        startTime: '2022-09-22',
        endTime: ''
      },
      majorList: [],
      majorStudentList: []
    }
  },
  watch: {
    'schoolStudentData.isOpen' () {
      if (this.schoolStudentData.isOpen) {
        this.listMajorStudentVo()
      }
    }
  },
  methods: {
    showMonthHandler (val) {
      if (val === '按日显示') {
        this.showMonth = true
        this.listMonthStudentVo()
      } else if (val === '按专业显示') {
        this.showMonth = false
        this.listMajorStudentVo()
      } else {
        this.showMonth = false
        this.listYearStudentVo()
      }
    },
    listMonthStudentVo () {
      listMonthSchoolStudentVo({
        schoolId: this.schoolStudentData.schoolId,
        month: moment(this.month).format('yyyy-MM')
      }).then(res => {
        const data = res.data
        this.dayList = []
        this.dayStudentList = []
        data.forEach(item => {
          this.dayList.push(item.day)
          this.dayStudentList.push(item.studentCount)
        })
        const myEcharts5 = echarts.init(
          document.getElementById('echarts-box7')
        )
        myEcharts5.setOption({
          title: {
            text: this.schoolStudentData.schoolName
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              show: true,
              interval: 0, // 使x轴上的文字显示完全,
              // 设置一行显示几个字,自己设置
              formatter: function (params) {
                var newParamsName = ''
                var paramsNameNumber = params.length
                var provideNumber = 5
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''
                    var start = p * provideNumber
                    var end = start + provideNumber
                    if (p === rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr
                  }
                } else {
                  newParamsName = params
                }
                return newParamsName
              }
            },
            data: this.dayList
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '学生数量',
              data: this.dayStudentList,
              type: 'bar',
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
        })
      })
    },
    listYearStudentVo () {
      listYearSchoolStudentVo({
        schoolId: this.schoolStudentData.schoolId,
        year: moment(this.year).format('yyyy')
      }).then(res => {
        const data = res.data
        this.monthList = []
        this.monthStudentList = []
        data.forEach(item => {
          this.monthList.push(item.month)
          this.monthStudentList.push(item.studentCount)
        })
        const myEcharts4 = echarts.init(
          document.getElementById('echarts-box6')
        )
        myEcharts4.setOption({
          title: {
            text: this.schoolStudentData.promoterName
          },
          xAxis: {
            type: 'category',
            data: this.monthList
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '学生数量',
              data: this.monthStudentList,
              type: 'line',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              },
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
        })
      })
    },
    listMajorStudentVo () {
      this.queryInfo.schoolId = this.schoolStudentData.schoolId
      listMajorStudentVo(this.queryInfo).then(res => {
        const data = res.data
        this.majorList = []
        this.majorStudentList = []
        data.forEach(item => {
          this.majorList.push(item.majorName)
          this.majorStudentList.push(item.studentCount)
        })
        const myEcharts8 = echarts.init(
          document.getElementById('echarts-box8')
        )
        myEcharts8.setOption({
          title: {
            text: this.schoolStudentData.schoolName
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '50',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            axisLabel: {
              show: true,
              interval: 0 // 使x轴上的文字显示完全,
            },
            data: this.majorList.reverse()
          },
          series: [
            {
              name: '学生数量',
              type: 'bar',
              data: this.majorStudentList.reverse(),
              label: {
                show: true,
                position: 'right'
              }
            }
          ]
        })
      })
    },
    closeHandler () {
      this.dateSelect = '按专业显示'
      this.showMonth = false
      this.$emit('closeDialog', { isOpen: false })
    },
    reset () {
      this.queryInfo.startTime = ''
      this.queryInfo.endTime = ''
      this.listMajorStudentVo()
    }
  }
}
</script>

<style scoped></style>
