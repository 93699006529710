<template>
  <div>
    <div id="saleChart" style="width: 100%;height: 1200px;"></div>
  </div>
</template>

<script>
import { listPromoterCountVo } from '@/api/homeChart'
import * as echarts from 'echarts'

export default {
  props: {
    queryInfo: {
      startTime: String,
      endTime: String
    }
  },
  data () {
    return {
      promoterNameList: [],
      payStudentCountList: []
    }
  },
  mounted () {
    this.listPromoterCountVo()
  },
  watch: {
    'queryInfo.startTime' () {
      this.listPromoterCountVo()
    },
    'queryInfo.endTime' () {
      this.listPromoterCountVo()
    }
  },
  methods: {
    listPromoterCountVo () {
      listPromoterCountVo(this.queryInfo).then(res => {
        const data = res.data
        this.promoterNameList = []
        this.payStudentCountList = []
        data.forEach(item => {
          this.promoterNameList.push(item.promoterName)
          this.payStudentCountList.push(item.payStudentCount)
        })
        const myEcharts = echarts.init(document.getElementById('saleChart'))
        myEcharts.setOption({
          title: {
            text: '推广员'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            interval: 0,
            axisLabel: {
              show: true,
              interval: 0, // 使x轴上的文字显示完全,
              // 设置一行显示几个字,自己设置
              formatter: function (params) {
                var newParamsName = ''
                var paramsNameNumber = params.length
                var provideNumber = 5
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''
                    var start = p * provideNumber
                    var end = start + provideNumber
                    if (p === rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr
                  }
                } else {
                  newParamsName = params
                }
                return newParamsName
              }
            },
            data: this.promoterNameList.reverse()
          },
          series: [
            {
              name: '学生数量',
              type: 'bar',
              data: this.payStudentCountList.reverse(),
              label: {
                show: true,
                position: 'right'
              }
            }
          ]
        })
      })
    }
  }
}
</script>

<style scoped></style>
