<template>
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card style="text-align: center">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-date-picker
                v-model="queryInfo.startTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择开始日期"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="6">
              <el-date-picker
                v-model="queryInfo.endTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择结束日期"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="6">
              <el-button type="warning" @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- 学校统计表格 -->
      <el-col :span="12">
        <el-card>
          <school-table :query-info="queryInfo" />
        </el-card>
      </el-col>
      <!-- 学校统计图表 -->
      <el-col :span="12">
        <el-card>
          <school-chart :query-info="queryInfo" />
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- 销量排行榜 -->
      <el-col :span="12">
        <el-card>
          <sales-table :query-info="queryInfo" />
        </el-card>
      </el-col>
      <!-- 已支付学生数量 -->
      <el-col :span="12">
        <el-card>
          <pay-mount :query-info="queryInfo" />
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- 推广员图表 -->
      <el-card :span="24">
        <sales-chart :query-info="queryInfo" />
      </el-card>
    </el-row>
  </div>
</template>

<script>
import schoolTable from './charts/schoolTable.vue'
import schoolChart from './charts/schoolChart.vue'
import payMount from './charts/payMount.vue'
import salesTable from './charts/salesTable.vue'
import salesChart from './charts/salesChart.vue'

export default {
  components: {
    schoolTable,
    schoolChart,
    payMount,
    salesTable,
    salesChart
  },
  data () {
    return {
      queryInfo: {
        startTime: '2022-09-22',
        endTime: ''
      },
      menuList: [
        {
          name: '院校管理',
          path: 'college',
          icon: 'el-icon-s-help'
        },
        {
          name: '报名管理',
          icon: 'el-icon-message-solid',
          path: 'student'
        },
        {
          name: '订单管理',
          icon: 'el-icon-s-goods',
          path: 'order'
        },
        {
          name: '推广管理',
          icon: 'el-icon-user-solid',
          path: 'promoter'
        },
        {
          name: '权限管理',
          icon: 'el-icon-setting',
          path: 'power'
        }
      ]
    }
  },
  methods: {
    reset () {
      this.queryInfo.startTime = ''
      this.queryInfo.endTime = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
